import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./services/auth.guard";
import { NotAuthenticated } from "./services/not-auth.guard";

const routes: Routes = [
	{
		path: "home",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
	},
	{
		path: "",
		redirectTo: "dashboard",
		pathMatch: "full",
	},
	{
		path: "home",
		canActivate: [NotAuthenticated],
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
	},
	{
		path: "registration",
		loadChildren: () =>
			import("./registration/registration.module").then(
				(m) => m.RegistrationPageModule
			),
	},
	{
		path: "verify-email",
		loadChildren: () =>
			import("./verify-email/verify-email.module").then(
				(m) => m.VerifyEmailPageModule
			),
	},
	{
		path: "login",
		loadChildren: () =>
			import("./login/login.module").then((m) => m.LoginPageModule),
	},
	{
		path: "dashboard",
		//canActivate: [AuthGuard],
		loadChildren: () =>
			import("./dashboard/dashboard.module").then(
				(m) => m.DashboardPageModule
			),
	},
	{
		path: "song",
		canActivate: [AuthGuard],
		loadChildren: () =>
			import("./song/song.module").then((m) => m.SongPageModule),
	},
	{
		path: "song-modal",
		loadChildren: () =>
			import("./song-modal/song-modal.module").then(
				(m) => m.SongModalPageModule
			),
	},  {
    path: 'dashboard-collabs',
    loadChildren: () => import('./dashboard-collabs/dashboard-collabs.module').then( m => m.DashboardCollabsPageModule)
  },

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			paramsInheritanceStrategy: "always",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
