import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from "@angular/fire/auth";
import { map, switchMap } from 'rxjs/operators';
import {Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  	constructor(public authService: AuthService, public ngFireAuth: AngularFireAuth, public router: Router) { }

  	public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
	    return new Promise((resolve, reject) => {
		    this.ngFireAuth.onAuthStateChanged((user) => {
		        if (user) {
		        	//this.router.navigate([ 'dashboard' ]);
		        	//this.router.parseUrl('dashboard');
		          	resolve(true);
		        } else {
		          	//this.router.navigate([ 'home' ]); //, { queryParams: { returnUrl: state.url } });
		          	resolve(false);
		        }
		    });
	    });
	  }


	
}
