// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
  	firebaseConfig: {
		  apiKey: "AIzaSyB2IH05IHmGEa6sYWV0iLCmAOeSKWFyLkE",
	   	authDomain: "ditty-quill.firebaseapp.com",
	   	databaseURL: "https://ditty-quill-default-rtdb.firebaseio.com/",
	   	projectId: "ditty-quill",
   		storageBucket: "ditty-quill.appspot.com",
    	messagingSenderId: "902897377362",
    	appId: "1:902897377362:web:2c46f84e26b9d020e1bf8b",
    	measurementId: "G-HEP7QWB4SX"
	 }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
