import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FormatPreviewPipe } from "../pipes/format-preview.pipe";

@NgModule({
    declarations:[ FormatPreviewPipe ], // <---
    imports:[CommonModule],
    exports:[ FormatPreviewPipe ] // <---
  })
  
  export class SongPipesModule{}