import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
//import { AngularFireAuth } from '@angular/fire/auth';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";

import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { DashboardMenuPopoverComponent } from "./dashboard-menu-popover/dashboard-menu-popover.component";
import { SongPipesModule } from './pipes/song-pipes.module';
//import { FormatPreviewPipe } from './pipes/format-preview.pipe';

@NgModule({
	declarations: [AppComponent, DashboardMenuPopoverComponent],
	entryComponents: [ DashboardMenuPopoverComponent ],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireDatabaseModule,
		AngularFirestoreModule,
		SongPipesModule
		//AngularFireAnalyticsModule,
	],
	providers: [
		StatusBar,
		SplashScreen,
		AngularFirestoreModule,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
	exports: [ ]
})
export class AppModule {}
