import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";

//import firebase from '@firebase/app';
import firebase from "firebase/app";
//import auth from '@firebase/app';
//import { auth } from 'firebase/app';
//import 'firebase/auth';
//import { FirebaseService } from './firebase.service';
import { User } from "../models/user";
import { AngularFireAuth } from "@angular/fire/auth";
import {
	AngularFirestore,
	AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { Song } from "../models/song";
import Timestamp = firebase.firestore.Timestamp;
import { SectionType } from "../models/section-types";
//import { resolve } from "dns";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	userData: any;
	public userRef: AngularFirestoreDocument<any>;

	constructor(
		public afStore: AngularFirestore,
		public ngFireAuth: AngularFireAuth,
		public router: Router,
		public ngZone: NgZone
	) {
		this.ngFireAuth.authState.subscribe((user) => {
			if (user) {
				this.userData = user;
				localStorage.setItem("user", JSON.stringify(this.userData));
				JSON.parse(localStorage.getItem("user"));
				this.userRef = this.afStore.doc(`users/${user.uid}`);
			} else {
				localStorage.setItem("user", null);
				JSON.parse(localStorage.getItem("user"));
			}

			firebase.database.enableLogging(true);
		});
	}

	// Login in with email/password
	SignIn(email, password) {
		return this.ngFireAuth.signInWithEmailAndPassword(email, password);
	}

	// Register user with email/password
	RegisterUser(email, password) {
		return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
	}

	// Email verification when new user register
	SendVerificationMail() {
		return this.ngFireAuth.currentUser
			.then((user) => {
				return user.sendEmailVerification();
			})
			.then(() => {
				this.router.navigate(["verify-email"]);
			});
	}

	// Recover password
	PasswordRecover(passwordResetEmail) {
		this.ngFireAuth
			.sendPasswordResetEmail(passwordResetEmail)
			.then(() => {
				window.alert(
					"Password reset email has been sent, please check your inbox."
				);
			})
			.catch((error) => {
				window.alert(error);
			});
	}

	// Returns true when user is looged in
	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem("user"));
		return user !== null && user.emailVerified !== false ? true : false;
	}

	// Returns true when user's email is verified
	get isEmailVerified(): boolean {
		const user = JSON.parse(localStorage.getItem("user"));
		return user.emailVerified !== false ? true : false;
	}

	// Sign in with Gmail
	GoogleAuth() {
		return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
	}

	// Auth providers
	AuthLogin(provider) {
		if (!(<any>window).cordova) {
			return this.ngFireAuth
				.signInWithPopup(provider)
				.then((result) => {
					this.ngZone.run(() => {
						this.router.navigate(["dashboard"]);
					});
					this.SetUserData(result.user);
				})
				.catch((error) => {
					window.alert(error);
				});
		} else {
			return this.ngFireAuth
				.signInWithRedirect(provider)
				.then(() => {
					return this.ngFireAuth
						.getRedirectResult()
						.then((result) => {
							// This gives you a Google Access Token.
							// You can use it to access the Google API.
							//let token = result.user;
							// The signed-in user info.
							//let user = result.user;
							this.ngZone.run(() => {
								this.router.navigate(["dashboard"]);
							});
							this.SetUserData(result.user);
						})
						.catch(function (error) {
							// Handle Errors here.
							alert(error.message);
						});
				})
				.catch((error) => {
					window.alert(error);
				});
		}

		/*return this.ngFireAuth.signInWithPopup(provider)
		    .then((result) => {
		       	this.ngZone.run(() => {
		          	this.router.navigate(['dashboard']);
		        })
		      	this.SetUserData(result.user);
		    }).catch((error) => {
				  window.alert(error)
				  
		    })*/
	}

	// Store user in localStorage
	SetUserData(user) {
		this.userRef = this.afStore.doc(`users/${user.uid}`);
		let creationTime = firebase.firestore.Timestamp.now();

		const userData: User = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			photoURL: user.photoURL,
			emailVerified: user.emailVerified,
		};

		var introSong: Song = {
			id: this.afStore.createId(),
			sections: [
				{
					type: SectionType.Intro,
					contents: 'Click "Intro." -->',
				},
				{
					type: SectionType.Verse,
					contents:
						"You must add sections to your song to type lyrics.",
				},
				{
					type: SectionType.PreChorus,
					contents:
						"You can even reorder your sections by dragging the numbers.",
				},
				{
					type: SectionType.Chorus,
					contents:
						"You can delete a section by clicking into the menu and selecting 'delete'.",
				},
				{
					type: SectionType.Bridge,
					contents:
						"You can even copy song sections!  Click into the section menu, then click copy.\n\nYou can paste the copied section in the song you're currently work on or a different song.",
				},
				{
					type: SectionType.Chorus,
					contents:
						"Don't forget to save your work.  An icon at the top will notify you when you have unsaved changes.",
				},
				{
					type: SectionType.Outro,
					contents: "Enjoy!",
				},
			],
			writers: [],
			artists: [],
			title: "Your First Song",
			created: creationTime,
			modified: creationTime,
			note: "You can add notes about your song here!",
			collaborators: [],
			invites: [],
			owner: null,
			owner_name: null
		};

		return this.userRef
			.set(userData, {
				merge: true,
			})
			.then(() => {
				this.afStore.firestore
					.collection("users")
					.doc(user.uid)
					.collection("songs")
					.get()
					.then((querySnapshot) => {
						var colSize = querySnapshot.size;
						if (colSize == 0) {
							let id = this.afStore.createId();
							this.userRef
								.collection("songs")
								.doc(introSong.id)
								.set(introSong)
								.then(() => {
									this.router.navigate(["dashboard"]);
								});
						}
					});
			});
	}

	CheckForSongsCollection() {}

	// Sign-out
	async SignOut() {
		return this.ngFireAuth.signOut().then(() => {
			localStorage.removeItem("user");
			//this.router.navigate(["dashboard"]);
		});
	}
}
