import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-dashboard-menu-popover',
  templateUrl: './dashboard-menu-popover.component.html',
  styleUrls: ['./dashboard-menu-popover.component.scss'],
})
export class DashboardMenuPopoverComponent implements OnInit {

	isCollab: boolean;

  	constructor(public popoverController: PopoverController, public navParams: NavParams) { 
		this.isCollab = this.navParams.get('isCollab');
	  }

	  ngOnInit() {}
	  
	ngAfterViewChecked(){
		if(this.isCollab) this.ChangeColors();
	}

	ionViewDidEnter(){
		if(this.isCollab) this.ChangeColors();
	}

  	LogOut(){
  		this.popoverController.dismiss("LogOut");
  	}

  	AddSong(){
  		this.popoverController.dismiss("AddSong");
	}
	  
	ChangeColors(){
		const generalList = document.querySelectorAll("ion-icon.general");
		generalList.forEach(genElem => {
			genElem.setAttribute('style', 'color:#006600');
		})

		const elemList = document.querySelectorAll("ion-list");
		elemList.forEach(elem => {
			elem.setAttribute('style', 'background-color:#006600');
		})
	}

}
