import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPreview'
})
export class FormatPreviewPipe implements PipeTransform {

  transform(sections: any, minLength: number, maxLength: number): string {
    return this.GetSectionContents(sections);
  }

  private GetSectionContents(sects: any): string{
    let text = "";
    for (let s of sects){
      text += s['contents'] + " ";
    }

    let re = /(?:\r\n|\r|\n)/g;
    text = text.replace(re, " ");

    if(text.length > 313){
      text = text.slice(0, 313) + "...";
    }else{
      text = "This is where your song lyrics will show as you compose each part of your song. There are currently no lyrics added to this song. Click here to add your lyrics now.  You'll have the option to add lyrics to each part of the song (verse, chorus, bridge, etc.), and save these parts of the song as you compose the song."
    }
    return text;
  }

}
